@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assets/fonts/Agustina.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
	color: var(--color-text);
	background: var(--color-bg) url(../src/assets/images/bg.jpg) no-repeat 50% 50% fixed;
	background-size: cover;
	font-family: "iki-mono-variable", sans-serif;
	font-variation-settings: "wdth" 80, "wght" 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}
